import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import './scss/style.scss';
import AuthService from './_services/authentication.service';
import Dashboard from './views/dashboard/Dashboard';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner  sk-spinner-pulse"></div>
  </div>
)

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        AuthService.isAuthenticated(localStorage.getItem('chekkitsupplychainUser')) ? (
          <TheLayout {...props} />
        ) : (
          <Redirect to="/login" /> // Redirect to /login if not authenticated
        )
      }
    />
  );
};

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

class App extends Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <PrivateRoute path="/dashboard" component={Dashboard} />
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            <Route exact path="/register" name="Register Page" render={(props) => <Register {...props} />} />
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
            <Redirect to="/login" /> {/* Set /login as the default route */}
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
