export const config =  {
  MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME"
  },
  apiGateway: {
    REGION: "YOUR_API_GATEWAY_REGION",


    // BASE_URL: 'http://localhost:3000/api/v1/',

    // BASE_URL: 'http://ec2-54-212-135-249.us-west-2.compute.amazonaws.com:3000/api/v2/',


    // BASE_URL: 'https://api-supply-chain.chekkit.app/api/v1/'

        BASE_URL: 'https://supply-chain-backend.azurewebsites.net/api/v1/'


    

    // BASE_URL: 'https://supplychaindev.azurewebsites.net/api/v1/'

  },
  cognito: {
    REGION: "YOUR_COGNITO_REGION",
    USER_POOL_ID: "YOUR_COGNITO_USER_POOL_ID",
    APP_CLIENT_ID: "YOUR_COGNITO_APP_CLIENT_ID",
    IDENTITY_POOL_ID: "YOUR_IDENTITY_POOL_ID"
  }
};

 