import React from 'react'


    const Dashboard = () => {

      const styles = {
        container: 'flex flex-row container mx-auto relative  justify-center w-full col-3 w-screen h-screen bg-amber-500 p-10 m-20',
        containerOne: 'bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500 box-border h-screen w-96 border-white border-4 drop-shadow-2xl',
        containerTwo: 'bg-gradient-to-r from-blue-500 to-transparent box-border h-screen w-96 border-white border-4 absolute bottom-0 right-60 drop-shadow-2xl',
        containerThree: 'bg-gradient-to-r from-transparent to-blue-500 box-border h-screen w-96 border-white border-4 absolute bottom-0 left-60 drop-shadow-2xl'


      }


  return (
    <>
    <div className="m-10">
    <h1 class="text-4xl text-center font-bold uppercase">
            <span class="bg-clip-text text-transparent bg-gradient-to-r from-red-500 via-yellow-500 to-blue-500">
                Welcome to Chekkit Supply Chain Admin Dashboard
            </span>
        </h1>      <br></br>
    </div>
    
      <div className={styles.container}>
          <div className={styles.containerOne}>
          </div>
          <div className={styles.containerTwo}>
          </div>
          <div className={styles.containerThree}>
          </div>
      </div>
      <div className="w-full bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500 h-32">
      </div>
    </>
  )
}

export default Dashboard
 