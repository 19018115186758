import axios from "axios";
import { config } from '../config';

const API_URL = config.apiGateway.BASE_URL + "auth/";


class AuthService {
    login(email, password) {
      return axios
        .post(API_URL + "signin/web", {
          email, 
          password
        })
        .then(response => {
          console.log(response.data.data)

          if (response.data.data.token) {
            console.log(3333)
            // response.data.data.token = response.data.data.token;
            localStorage.setItem("chekkitsupplychainUser", JSON.stringify(response.data.data));
          }
  
          return response.data.data;
        });
    }

    logout() {
      console.log('logged out')
      localStorage.removeItem("chekkitsupplychainUser");
    }
  
    register(name, email, password, companyName, address, country, companyRole, companyIdentfier) {
      return axios.post(API_URL + "signup", {
        name, email, password, companyName, address, country, companyRole, companyIdentfier
      });
    }

    isAuthenticated(token) {
      if (token) return true;
    } 
  
    getCurrentUser() {
      let u = JSON.parse(localStorage.getItem('chekkitsupplychainUser'));
      return u
    }

}

const myInstance = new AuthService();
export default myInstance;

// export default new AuthService();
